export const formdata = [
    {
        name: "",
        element: ""
    },
    {
        name: "Drawing Details :",
        element: "input-table-custom-drawing",
        id: "table1",
        table: [
            {
                figure_no: "",
                figure_description: "",
                visibility: false,
                table: [
                    {
                        component_no: "",
                        component_name: "",
                        component_description: "",
                        component_connected_component: ""
                    },
                ]
            },
        ],
        required: false,
        optional: false,
    },
]
export const fieldNames = [
    "pa_limitations",
];


export const storageName = "masterDFD"


export const requiredCheck = [{
    field: "pa_limitations",
    Message: "Please fill the Limitations/disadvantages of prior art"
}]

export const agreementType = "Figures and Drawings"

export const api = "/api/drawings_and_ddpe" //no need
export const apiRedirection = "/app/dfd"
export const downloadDocName = "Figures_and_Drawing"
export const nextTo = "/app/claim-selection"
export const previousTo = ""