import { useState } from "react"
import React from 'react'
import axios from "axios";
import "../css/draftAgreement.css";
import { getNotification, } from "../../Redux/Action/action.js";
import UserService from "../../services/UserService.js";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";


const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;
const Progress = ({ progress }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();


    const download = async () => {
        if (UserService.isLoggedIn()) {
            try {
                const payload = {
                    keycloak_user_id: UserService.getUserId(),
                    agreement_type: ["scripter1", "scripter2", "scripter3", "scripter4", "scripter5", "scripter6"],
                    session_id: ""
                };

                setIsLoading(true)
                const getResponse = await axios.post(REACT_APP_CREATE_AGREEMENT_API + "/api/create_final_agreement_and_export_as_docx", payload,
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: UserService.getToken(),
                        },
                        responseType: "blob",
                    }
                );

                if (getResponse.status === 200) {
                    const text = getResponse.data.agreement_text;
                    // getPlanDetails("noRefresh");
                    const getCurrentDate = () => {
                        const currentDate = new Date();
                        const year = String(currentDate.getFullYear()).slice(-4);
                        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
                        const day = String(currentDate.getDate()).padStart(2, "0");
                        return `${year}-${day}-${month}`;
                    };
                    const blob = new Blob([getResponse.data], {
                        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${"Patent" + UserService.getUsername()}_${getCurrentDate()}.docx`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch(
                        getNotification({
                            message: "Downloaded successfully",
                            type: "success",
                        })
                    );
                } else if (getResponse.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}` });
                    }, 3000);
                }
            } catch (err) {
                console.log("Error", err);
                dispatch(
                    getNotification({
                        message: "We are experiencing high demand. Please try after a few minutes.",
                        type: "default",
                    })
                );
            }
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}` });
            }, 3000);
        }
        setIsLoading(false)
    };



    return (
        <section className="text-gray-600 body-font">
            <div className="lg:w-full md:w-1/2 md:pr-10 md:py-6">
                {progress && progress.map((element, index) => (
                    <div key={index} className="flex relative">
                        {progress.length - 1 !== index && <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                            <div className={`h-full w-1 ${element.done ? "btn-primary" : "bg-gray-200"} pointer-events-none`}></div>
                        </div>}
                        <div className="flex-shrink-0 w-10 h-10 rounded-full btn-primary inline-flex items-center justify-center text-white relative z-10">
                            {element.icon ? element.icon :
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                    <path d="M22 4L12 14.01l-3-3"></path>
                                </svg>
                            }
                        </div>
                        {
                            progress.length - 1 === index ?
                                <div className="flex-grow pl-4">
                                    <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider my-1 fw-bold">{element.name}</h2>
                                    <div className="py-2 px-2 shadow-xl rounded-3xl text-center">
                                        <button
                                            spiketip-title="Download Complete Application" spiketip-pos="bottom"
                                            onClick={() => download()}
                                            className={`mt-3 my-4 px-3 py-2 text-lg btn btn-outline-primary ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        // disabled={isLoading}
                                        >
                                            <i className="fas fa-download"></i>
                                            <span className="mx-2">{isLoading ? 'Downloading...' : 'Download'}</span>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className="flex-grow pl-4">
                                    <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider my-1 fw-bold">{element.name}</h2>
                                    <div className="p-3 shadow-md rounded-3xl">
                                        <p style={{ width: "200px" }} className="leading-relaxed mb-0">Last Used :<br />{element.lastDate} - {element.lastTime} </p>
                                    </div>
                                </div>
                        }
                    </div>
                ))
                }
            </div>
        </section >
    )
}

export default Progress